import { Box, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import timezone from "dayjs/plugin/timezone";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "dayjs/locale/es-mx";

dayjs.locale("es-mx");

dayjs.extend(timezone);

const localizer = dayjsLocalizer(dayjs);

const CustomComponent = (props) => {
  return (
    <Box
      bg={props.event.user === "USER" ? "brand.green1" : "green-800"}
      color={"white"}
      textAlign={"center"}
      borderColor={props.event.user === "USER" ? "brand.green1" : "green-800"}
      px="4"
      py="1"
      fontSize="xs"
    >
      {props.event.user}
    </Box>
  );
};

const TareaHistorical = ({ historical, type }) => {
  const historicalMapped = historical.map(
    ({ date_check, type_user_checked }) => {
      const date = dayjs.utc(date_check);
      return { date: date.format("YYYY-MM-DD"), user: type_user_checked };
    }
  );

  console.log("historicalMapped", historicalMapped);

  return (
    <Flex alignItems={"center"} justifyContent={"center"}>
      <Calendar
        messages={{
          week: "Semana",
          work_week: "Semana de trabajo",
          day: "Día",
          month: "Mes",
          previous: "Atrás",
          next: "Siguiente",
          today: "Hoy",
          agenda: "Diariamente",

          showMore: (total) => `+${total} más`,
        }}
        culture="es"
        localizer={localizer}
        events={historicalMapped.map((act) => {
          return {
            user: act.user,
            start: new Date(act.date),
            end: new Date(act.date),
            allDay: true,
          };
        })}
        dayLayoutAlgorithm="no-overlap"
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, width: "100%" }}
        components={{ event: CustomComponent }}
        views={["month"]}
      />
    </Flex>
  );
};

export default TareaHistorical;
