import React, { useEffect, useMemo, useState } from "react";
import {
  HStack,
  VStack,
  IconButton,
  Image,
  Text,
  Box,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Button,
  useToast,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  ArrowBackIcon,
  BellIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  agenda,
  casas,
  procesos,
  docs,
  tareas,
  usericon,
  buildings,
} from "../assets/icons/icons";
import { useAuth, useGetToken } from "../hooks/useAuth";

import UserMenu from "../components/UserMenu";
import { useLazyGetNotificationsQuery } from "../store/heraApi";
import { Client } from "@pusher/push-notifications-web";
import { iOS } from "../constans/utils";
import NotificationCard from "../components/NotificationCard";

const MESSAGE =
  "No se pudo registrar con éxito para recibir notificaciones push. Verifica el dispositivo o en su defecto el navegador.";

const DefaultLayout = () => {
  useAuth();

  const { user } = useGetToken();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [notificationsFetcher, { data }] = useLazyGetNotificationsQuery();
  const toast = useToast();

  const [collapsed, setCollapsed] = useState(false);
  const [section, setSection] = useState("");

  const IF_DEV_OR_IOS = !iOS() && process.env.NODE_ENV === "production";

  useEffect(() => {
    if (location.pathname && !section) {
      const screenName = location.pathname.replaceAll("/", "");
      setSection(screenName);
    }
  }, [location.pathname, section]);

  useEffect(() => {
    if (user?.id) notificationsFetcher(user?.id).unwrap();
  }, [user?.id, location.pathname, notificationsFetcher]);

  function pusherConnection() {
    if (process.env.REACT_APP_INSTACE_ID) {
      return process.env.REACT_APP_INSTACE_ID;
    }
    return process.env.NODE_ENV === "production"
      ? "5f425227-5752-4a90-b45a-2e632f35798e"
      : "457c1b25-83b9-46c9-a725-6f4483854e89";
  }

  const beamsClient = useMemo(() => {
    try {
      return IF_DEV_OR_IOS
        ? new Client({
            instanceId: pusherConnection(),
          })
        : null;
    } catch (error) {
      return null;
    }
  }, [IF_DEV_OR_IOS]);

  useEffect(() => {
    try {
      if (IF_DEV_OR_IOS) {
        beamsClient
          .start()
          .then(() => beamsClient.addDeviceInterest("hello"))
          .then(() => console.log("Successfully registered and subscribed!"))
          .catch((e) => {
            console.log(e);
            toast({
              title: "Error - Notificaciones push",
              description: MESSAGE,
              status: "warning",
              duration: 3000,
              isClosable: true,
            });
          });
      } else {
        toast({
          title: "Error - Notificaciones push",
          description: MESSAGE,
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error - Notificaciones push",
        description: MESSAGE,
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [IF_DEV_OR_IOS, beamsClient, toast]);

  return (
    <HStack w="full" align={"start"} gap="0" minH="100vh">
      <Drawer
        isOpen={collapsed}
        placement="left"
        onClose={() => setCollapsed(false)}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent>
          <VStack w="full" spacing={"1rem"} alignItems={"start"}>
            <IconButton
              w="full"
              icon={<Image src="/logo-w.svg" alt="Logo" />}
              bg="brand.blue1"
              rounded="none"
            />
            <IconButton
              w="full"
              icon={<ArrowBackIcon alt="cerrar menú" />}
              color="brand.blue1"
              rounded="none"
              bg="transparent"
              onClick={() => setCollapsed(false)}
            />

            <Button
              w="full"
              onClick={() => {
                navigate("/inicio");
                setSection("Inicio");
                setCollapsed(false);
              }}
              leftIcon={<Image src={casas} />}
              justifyContent={"start"}
              bgColor={section === "Inicio" ? "gray.300" : "white"}
            >
              Home
            </Button>
            <Button
              w="full"
              onClick={() => {
                navigate("/tareas");
                setSection("Tareas");
                setCollapsed(false);
              }}
              bgColor={section === "Tareas" ? "gray.300" : "white"}
              leftIcon={<Image src={tareas} />}
              justifyContent={"start"}
            >
              Tareas
            </Button>
            {(user?.rol?.includes("admin") ||
              user?.rol?.includes("supervisor")) && (
              <Button
                bgColor={section === "inactivas" ? "gray.300" : "white"}
                onClick={() => {
                  navigate("/inactivas");
                  setSection("inactivas");
                  setCollapsed(false);
                }}
                w="full"
                leftIcon={<RepeatIcon color="gray" />}
                justifyContent={"start"}
              >
                Inactivas
              </Button>
            )}
            <Button
              bgColor={section === "Agenda" ? "gray.300" : "white"}
              onClick={() => {
                navigate("/agenda");
                setSection("Calendario");
                setCollapsed(false);
              }}
              leftIcon={<Image src={agenda} />}
              w="full"
              justifyContent={"start"}
            >
              Calendario
            </Button>
            <Button
              bgColor={section === "Procesos" ? "gray.300" : "white"}
              onClick={() => {
                navigate("/procesos");
                setSection("Procesos");
                setCollapsed(false);
              }}
              leftIcon={<Image src={procesos} />}
              w="full"
              justifyContent={"start"}
            >
              Procesos
            </Button>
            <Button
              bgColor={section === "Documentos" ? "gray.300" : "white"}
              onClick={() => {
                navigate("/documentos");
                setSection("Documentos");
                setCollapsed(false);
              }}
              leftIcon={<Image src={docs} />}
              w="full"
              justifyContent={"start"}
            >
              Documentos
            </Button>
            {user?.rol?.includes("admin") && (
              <Button
                bgColor={section === "Usuarios" ? "gray.300" : "white"}
                onClick={() => {
                  navigate("/usuarios");
                  setSection("Usuarios");
                  setCollapsed(false);
                }}
                leftIcon={<Image src={usericon} />}
                w="full"
                justifyContent={"start"}
              >
                Usuarios
              </Button>
            )}
            {(user?.rol?.includes("admin") ||
              user?.rol?.includes("supervisor")) && (
              <Button
                bgColor={section === "Áreas" ? "gray.300" : "white"}
                onClick={() => {
                  navigate("/areas");
                  setSection("Áreas");
                  setCollapsed(false);
                }}
                leftIcon={<Image src={buildings} />}
                w="full"
                justifyContent={"start"}
              >
                Áreas
              </Button>
            )}
          </VStack>
        </DrawerContent>
      </Drawer>

      <VStack w="full" minH="100vh" bg="#F3F0F9" p="0">
        <HStack h="12" w="full" bg="white" shadow="sm" align={"center"}>
          <HStack>
            <IconButton
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="none"
              color="brand.blue1"
              onClick={() => setCollapsed(!collapsed)}
            />
            <Text fontWeight={900} textTransform={"capitalize"}>
              {section}
            </Text>
          </HStack>
          <HStack ml="auto" mr="4">
            <Box position={"relative"} onClick={onOpen} cursor="pointer">
              {Array.isArray(data) && data.length > 0 > 0 && (
                <Box
                  w="4"
                  h="4"
                  rounded="full"
                  bg="#D9D9D9"
                  position={"absolute"}
                  right="0"
                  top="0"
                  border="1"
                  borderColor="white"
                />
              )}
              <BellIcon boxSize={8} color="brand.blue1" />
            </Box>
            <UserMenu user={user} />
          </HStack>
        </HStack>
        <Outlet />
      </VStack>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontWeight={900}>Notificaciones</DrawerHeader>

          <DrawerBody>
            {Array.isArray(data) &&
              data.length > 0 &&
              data.map((notification, i) => (
                <NotificationCard
                  notificationsFetcher={notificationsFetcher}
                  notification={notification}
                  key={`notification-card-${i}`}
                />
              ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
};

export default DefaultLayout;
