import {
  Box,
  HStack,
  IconButton,
  Select,
  Td,
  Text,
  Tr,
  WrapItem,
  Avatar,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CheckIcon } from "@chakra-ui/icons";
import { find, get } from "lodash";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { CHECK_VALIDATOR, ROLES, TYPES } from "../constans/utils";
import TareaEntregaTable from "./TareaEntregaTable";

dayjs.extend(isSameOrBefore);

const TareaTableRow = ({
  activity,
  user,
  dataStatus,
  statusHandlerSelection,
  userOnCheck,
  setDataDetail,
  onDetailOpen,
}) => {
  const [statusColor, setStatusColor] = useState("brand.blue2");
  const [localNextDate, setLocalNextDate] = useState(null);

  const checkValidatorUser = useMemo(
    () => CHECK_VALIDATOR(activity, ROLES.USER),
    [activity]
  );
  const checkValidatorAdmin = useMemo(
    () => CHECK_VALIDATOR(activity, ROLES.ADMIN),
    [activity]
  );

  const colorScheme =
    activity.type === TYPES.PROGRAMMED ? "brand.green1" : "brand.blue2";

  const rol = get(user, "rol", []);
  const isAdminOrSupervisor =
    rol.includes(ROLES.ADMIN) || rol.includes(ROLES.SUPERVISOR);
  const isUser = rol.includes(ROLES.USER);

  // console.log("<--- TareaTableRow --->");
  // console.log(activity.name);
  // console.log("localNextDate", localNextDate);
  // console.log("checkValidatorUser", checkValidatorUser);
  // console.log("checkValidatorAdmin", checkValidatorAdmin);

  const isOpenBasedOnTypeAndData = useCallback(() => {
    if (localNextDate === null) {
      return false;
    } else if (
      activity.type === TYPES.OPERATIVE &&
      activity.daily === true &&
      activity.finish_date
    ) {
      return dayjs(Date.now()).isSameOrBefore(
        dayjs(activity.finish_date).add(1, "day"),
        "day"
      );
    } else if (
      activity.type === TYPES.OPERATIVE &&
      activity.daily === true &&
      !activity.finish_date
    ) {
      return true;
    }
    return false;
  }, [activity, localNextDate]);

  const isOpenToCheckForUsers = useCallback(() => {
    const result = isOpenBasedOnTypeAndData();
    if (result) {
      return result;
    } else if (isUser && !checkValidatorUser?.date_check) {
      return true;
    } else if (isUser && checkValidatorUser?.date_check) {
      const isSameCheckAndNextDate = localNextDate?.isSame(
        dayjs(checkValidatorUser.date_check),
        "day"
      );
      return !isSameCheckAndNextDate;
    }

    return false;
  }, [localNextDate, checkValidatorUser, isUser, isOpenBasedOnTypeAndData]);

  const isOpenToCheckForAdmins = useCallback(() => {
    const result = isOpenBasedOnTypeAndData();
    if (result) {
      return result;
    } else if (isAdminOrSupervisor && !checkValidatorAdmin?.date_check) {
      return true;
    } else if (isAdminOrSupervisor && checkValidatorAdmin?.date_check) {
      const isSameCheckAndNextDate = localNextDate?.isSame(
        dayjs(checkValidatorAdmin.date_check),
        "day"
      );
      return !isSameCheckAndNextDate;
    }

    return false;
  }, [
    isOpenBasedOnTypeAndData,
    isAdminOrSupervisor,
    checkValidatorAdmin,
    localNextDate,
  ]);

  const userAssignedMapped = (activity.users_assigned ?? []).map(
    ({ label, value }) => {
      const [name, username] = label.split(" - ");
      return {
        name,
        username,
        id: value,
      };
    }
  );

  useEffect(() => {
    if (activity.estatus_id && Array.isArray(dataStatus)) {
      const [status] = dataStatus.filter(
        ({ id }) => parseInt(id) === parseInt(activity.estatus_id)
      );
      setStatusColor(status?.color);
    }
  }, [activity.estatus_id, dataStatus]);

  const calendarObject = useMemo(() => {
    const founded = find(activity.calendar, ({ start }) => {
      return dayjs.utc(start).isSame(activity.next_date, "day");
    });
    return founded ?? {};
  }, [activity]);

  return (
    <Tr bg="white">
      <Td
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        borderLeftWidth={"1px"}
        borderBottomLeftRadius={"10px"}
        borderTopLeftRadius={"10px"}
        pr="2"
        py="2"
        pl="0"
      >
        <HStack>
          <Box h="30px" w="8px" rounded="xl" mr="2" bg={colorScheme} />
          <Text
            cursor="pointer"
            fontSize="sm"
            onClick={() => {
              setDataDetail(activity);
              onDetailOpen();
            }}
          >
            {activity.name}
          </Text>
        </HStack>
      </Td>
      <Td
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        pr="2"
        py="2"
        pl="0"
      >
        <VStack>
          <TareaEntregaTable
            dataDetail={activity}
            setLocalNextDate={setLocalNextDate}
          />
          {/* {IS_DISABLED_BY_DATE === true && (
            <Text color="red.500" fontSize="xs">
              Tarea fuera de tiempo, verifica la fecha de inicio y fin
            </Text>
          )} */}
        </VStack>
      </Td>
      <Td
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        pr="2"
        py="2"
        pl="0"
      >
        {activity.type === TYPES.PROGRAMMED && (
          <Select
            bg={statusColor}
            borderColor={statusColor}
            color="white"
            w="48"
            fontSize={"14"}
            placeholder="Seleccione el estatus"
            value={activity.estatus_id}
            isDisabled={true}
            onChange={(e) => {
              statusHandlerSelection(e.target.value, activity.uuid);
              const [status] = dataStatus.filter(
                ({ id }) => parseInt(id) === parseInt(e.target.value)
              );
              setStatusColor(status?.color);
            }}
          >
            {Array.isArray(dataStatus) &&
              dataStatus.map(({ id, name }) => (
                <option
                  style={{ color: "black" }}
                  value={id}
                  key={`estatus-${id}-opt`}
                >
                  {name}
                </option>
              ))}
          </Select>
        )}
      </Td>
      <Td
        textAlign={"center"}
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        pr="2"
        py="2"
        pl="0"
      >
        {(activity.type === TYPES.PROGRAMMED ||
          activity.type === TYPES.OPERATIVE) &&
          (rol.includes(ROLES.ADMIN) || rol.includes(ROLES.SUPERVISOR)) && (
            <HStack>
              {userAssignedMapped.map(({ id, name }) => (
                <Box key={`user-${id}`}>
                  <Tooltip key={`operative-${id}-${name}`} label={name}>
                    <WrapItem>
                      <Avatar
                        size="sm"
                        name={name}
                        borderColor="brand.blue1"
                        borderWidth={"1px"}
                      />
                    </WrapItem>
                  </Tooltip>
                </Box>
              ))}
            </HStack>
          )}
        {(activity.type === TYPES.PROGRAMMED ||
          activity.type === TYPES.OPERATIVE) &&
          rol.includes(ROLES.USER) && (
            <IconButton
              isRound={true}
              variant="outline"
              borderWidth={"2px"}
              borderColor={
                isOpenToCheckForUsers() ? "brand.grays1" : "brand.blue1"
              }
              bg={isOpenToCheckForUsers() ? "none" : "brand.green2"}
              aria-label="Done"
              fontSize="16px"
              size="sm"
              icon={isOpenToCheckForUsers() ? null : <CheckIcon />}
              isDisabled={
                !isOpenToCheckForUsers() && isAdminOrSupervisor === false
              }
              onClick={() =>
                !user?.rol
                  ?.join("")
                  .includes(ROLES.ADMIN || ROLES.SUPERVISOR) &&
                userOnCheck(user?.rol?.join(""), activity.uuid, calendarObject)
              }
            />
          )}
      </Td>
      <Td
        textAlign={"center"}
        borderColor={colorScheme}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        borderRightWidth={"1px"}
        borderBottomRightRadius={"10px"}
        borderTopRightRadius={"10px"}
        pr="2"
        py="2"
        pl="0"
      >
        {activity.type === TYPES.OPERATIVE &&
          (rol.includes(ROLES.ADMIN) || rol.includes(ROLES.SUPERVISOR)) && (
            <HStack>
              {userAssignedMapped.map(({ id, name }) => (
                <Box key={`user-${id}`}>
                  <Tooltip key={`operative-${id}-${name}`} label={name}>
                    <WrapItem>
                      <Avatar
                        size="sm"
                        name={name}
                        borderColor="brand.blue1"
                        borderWidth={"1px"}
                      />
                    </WrapItem>
                  </Tooltip>
                </Box>
              ))}
            </HStack>
          )}
        {activity.type === TYPES.PROGRAMMED &&
          activity?.supervisor_ckecked?.name &&
          rol.includes(ROLES.USER) && (
            <Tooltip label={activity?.supervisor_ckecked?.name}>
              <WrapItem>
                <Avatar
                  size="sm"
                  name={activity?.supervisor_ckecked?.name}
                  borderColor="brand.blue1"
                  borderWidth={"1px"}
                />
              </WrapItem>
            </Tooltip>
          )}
        {activity.type === TYPES.PROGRAMMED &&
          (rol.join("") === ROLES.ADMIN ||
            rol.join("") === ROLES.SUPERVISOR) && (
            <IconButton
              isRound={true}
              variant="outline"
              borderWidth={"2px"}
              borderColor={
                isOpenToCheckForAdmins() ? "brand.grays1" : "brand.blue1"
              }
              bg={isOpenToCheckForAdmins() ? "none" : "brand.green2"}
              aria-label="Done"
              fontSize="16px"
              size="sm"
              icon={isOpenToCheckForAdmins() ? null : <CheckIcon />}
              isDisabled={!isOpenToCheckForAdmins() && isUser === false}
              onClick={() =>
                userOnCheck(user?.rol?.join(""), activity.uuid, calendarObject)
              }
            />
          )}
      </Td>
    </Tr>
  );
};

export default TareaTableRow;
